
import { Component, Vue, Prop, toNative } from 'vue-facing-decorator';
import { StoreHelper } from '@/store/StoreHelper';
import { LicenseApplicationRequestInfo } from '@/entities//Application/LicenseApplicationRequestInfo';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import loading from '@/components/Common/loading.vue';
// import Datepicker from '@/components/Common/Datepicker.vue';
import { ConsumptionBasedBilling } from '@/entities/ProductLicense/ConsumptionBasedBilling';
import { DateTimeOptions } from 'vue-i18n';
import { Store, EnvironmentBaseURL, FetchData } from '@/main';
@Component({
  components: {
    loading,
    // Datepicker,
  },
})
class LicenseDetails extends Vue {
  @Prop() private showModalPopup?: boolean;
  @Prop() private applicationId?: number;
  @Prop() private borderColor?: string;
  @Prop() private isLoggedInUserSuperAdmin?: string;
  @Prop() private isApplicationOnNewPackage?: boolean;
  @Prop() private isMeetAppLiveEnabled?: boolean;
  private localBorderColor = '';
  private applicationIconUrl = '';
  private applicationName = '';
  private applicationConsumedAdmins = 0;
  private applicationConsumedEvents = 0;
  private applicationCreateAccountType = '';
  private applicationActiveLicense: any;
  private applicationAddOns: any;
  private applicationLicenseItems: any;
  private applicationLicenseItemsThisYear: any;
  private applicationLicenseItemsnextYear: any;
  private applicationAddOnCountFiltered: any;
  private applicationPremiumFeaturesReadOnly: any;
  private groupedFeaturesListCount = 0;
  private groupedFeaturesList: any;
  private groupedFeaturesListAddOns: any;
  private isApplicationLegacyModel = false;
  private licenseApplication?: any;
  private dateparts?: any;
  private dateformat = '';
  private legacyPackageName = '';
  private localShowModalPopup?: boolean = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private todayDate: Date = new Date();
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private showLoading = false;
  private consumptionBasedProductStartDate: Date = new Date();
  private consumptionBasedProductEndDate: Date = new Date();
  private listConsumptionBasedProduct: ConsumptionBasedBilling[] = [];
  private MonthNameDataList: any = [];
  private selectedMonth = 0;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private isReportLoading?: boolean = false;
  private additionalParticipantSeatsCount = 0;
  private dateOptions: DateTimeOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  private Cancel() {
    this.$emit('close-license-popup', false);
  }

  private CheckNextLicenseItem(): boolean {
    const checkItems = this.applicationLicenseItems.some((r: any) => {
      r.AddedDate.setDate(r.AddedDate.getDate() + 1);
      return r.AddedDate > this.todayDate;
    });

    if (checkItems) {
      this.applicationLicenseItemsnextYear = [];
      this.applicationLicenseItems.forEach((element: any) => {
        element.AddedDate.setDate(element.AddedDate.getDate() + 1);
        if (element.AddedDate > this.todayDate) {
          this.applicationLicenseItemsnextYear.push(element);
        }
      });
      return true;
    } else {
      return false;
    }
  }
  private CheckPreviousLicenseItems(): boolean {
    const self = this;
    const checkItems = this.applicationLicenseItems.some((r: any) => {
      r.AddedDate.setDate(r.AddedDate.getDate() - 1);
      return r.AddedDate <= self.todayDate;
    });

    if (checkItems) {
      this.applicationLicenseItemsThisYear = [];
      this.applicationLicenseItems.forEach((element: any) => {
        element.AddedDate.setDate(element.AddedDate.getDate() - 1);
        if (element.AddedDate <= self.todayDate) {
          this.applicationLicenseItemsThisYear.push(element);
        }
      });
      return true;
    } else {
      return false;
    }
  }
  private EditLicense() {
    window.location.href =
      EnvironmentBaseURL +
      '/SuperAdmin/ApplicationLicense/AttachLicense/' +
      this.applicationId;
  }
  private formatDate(date: Date): string {
    return this.dateFormat.format(date).toUpperCase();
  }
  private GetAddOnCounts(): number {
    let addonCount = 0;
    if (this.groupedFeaturesListAddOns.length > 0) {
      addonCount = this.groupedFeaturesListAddOns.length;
    }
    return addonCount;
  }
  private GetPremiumFeaturesCount(): boolean {
    if (this.groupedFeaturesList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private GetCount(licenseItem: any): number {
    let counter = 0;
    if (licenseItem) {
      licenseItem.forEach((element: any) => {
        counter = counter + element.Quantity;
      });
    }
    return counter;
  }

  private GetCustomerLicenseDetails(applicationId: number) {
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const licenseApplicationRequestInfo: LicenseApplicationRequestInfo = storeHelper.GetLicenseApplicationFromStore();
    if (licenseApplicationRequestInfo) {
      this.localShowModalPopup = this.showModalPopup;
      this.isApplicationLegacyModel =
        licenseApplicationRequestInfo.isApplicationLegacyModel;
      this.applicationActiveLicense =
        licenseApplicationRequestInfo.ApplicationActiveLicense;
      this.applicationLicenseItems =
        licenseApplicationRequestInfo.ApplicationLicenseItems;
      this.applicationAddOns = licenseApplicationRequestInfo.ApplicationAddOns;
      this.applicationPremiumFeaturesReadOnly =
        licenseApplicationRequestInfo.applicationPremiumFeaturesReadOnly;
      this.groupedFeaturesList =
        licenseApplicationRequestInfo.GroupedFeaturesList;
      this.groupedFeaturesListAddOns =
        licenseApplicationRequestInfo.GroupedFeaturesListAddOns;
      this.applicationIconUrl = licenseApplicationRequestInfo.IconUrl;
      this.applicationName = licenseApplicationRequestInfo.Name;
      this.applicationCreateAccountType =
        licenseApplicationRequestInfo.CreateAccountType;
      this.applicationConsumedAdmins =
        licenseApplicationRequestInfo.ConsumedAdmins;
      this.applicationConsumedEvents =
        licenseApplicationRequestInfo.ConsumedEvents;
      this.additionalParticipantSeatsCount = licenseApplicationRequestInfo.AdditionalParticipantSeats;
      setTimeout(() => {
        this.GetBillingReportDetails();
      }, 500);
    } else {
      this.showLoading = true;
      FetchData('/ApplicationLicenseV2/ViewCustomerLicense', JSON.stringify({
        id: applicationId,
      }))
        .then((response) => {
          this.licenseApplication = response;
          this.isApplicationLegacyModel = this.licenseApplication.IsApplicationLegacyModel;
          this.applicationActiveLicense = this.licenseApplication.ApplicationLicense;
          this.applicationLicenseItems = this.licenseApplication.ApplicationLicenseItems;
          this.applicationAddOns = this.licenseApplication.ApplicationAddOns;
          this.applicationPremiumFeaturesReadOnly = this.licenseApplication.ApplicationPremiumFeaturesReadOnly;
          this.applicationIconUrl = this.licenseApplication.IconUrl;
          this.applicationName = this.licenseApplication.Name;
          this.applicationCreateAccountType = this.licenseApplication.CreateAccountType;
          this.applicationConsumedAdmins = this.licenseApplication.ConsumedAdmins;
          this.applicationConsumedEvents = this.licenseApplication.ConsumedEvents;
          this.legacyPackageName = this.licenseApplication.LegacyPackageName;
          this.groupedFeaturesList = this.licenseApplication.GroupedFeaturesList;
          this.groupedFeaturesListAddOns = this.licenseApplication.GroupedFeaturesListAddOns;
          this.additionalParticipantSeatsCount = this.licenseApplication.AdditionalParticipantSeats;

          const licenseApplication: LicenseApplicationRequestInfo = new LicenseApplicationRequestInfo(
            this.isApplicationLegacyModel,
            this.applicationActiveLicense,
            this.applicationLicenseItems,
            this.applicationAddOns,
            this.applicationPremiumFeaturesReadOnly,
            this.groupedFeaturesList,
            this.groupedFeaturesListAddOns,
            this.applicationName,
            this.applicationCreateAccountType,
            this.applicationConsumedAdmins,
            this.applicationConsumedEvents,
            this.applicationIconUrl,
            this.additionalParticipantSeatsCount,
          );
          storeHelper.SaveLicenseApplicationToStore(licenseApplication);
          this.localShowModalPopup = this.showModalPopup;
          this.showLoading = false;
          setTimeout(() => {
            this.GetBillingReportDetails();
          }, 500);
        })
        .catch(() => {
          // Log error if required
        });
    }
  }
  private mounted() {
    this.localBorderColor = this.borderColor!;
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(
      browsersettings.locale,
      this.dateOptions,
    );
    this.getLastTwelveMonthList();
    this.GetCustomerLicenseDetails(this.applicationId!);
  }
  private SetStartDateValue(date: Date) {
    this.consumptionBasedProductStartDate = date;
  }
  private SetEndDateValue(date: Date) {
    this.consumptionBasedProductEndDate = date;
  }
  private GetStartDate(): Date {
    if (typeof this.consumptionBasedProductStartDate.getDate !== 'undefined') {
      this.consumptionBasedProductStartDate.setDate(this.consumptionBasedProductStartDate.getDate() - 7);
    }
    return this.consumptionBasedProductStartDate;
  }
  private GetEndDate(): Date {
    return this.consumptionBasedProductEndDate;
  }
  private getDateFormat(): string {
    const mydate = new Date();
    let separator = '';
    let first = '';
    let second = '';
    let third = '';
    if (mydate.toLocaleDateString().split('-').length === 3) {
      separator = '-';
      this.dateparts = mydate.toLocaleDateString().split('-');
    }
    if (mydate.toLocaleDateString().split('/').length === 3) {
      separator = '/';
      this.dateparts = mydate.toLocaleDateString().split('/');
    }
    if (mydate.toLocaleDateString().split('.').length === 3) {
      separator = '.';
      this.dateparts = mydate.toLocaleDateString().split('.');
    }
    if (mydate.toLocaleDateString().split(' ').length === 3) {
      separator = ' ';
      this.dateparts = mydate.toLocaleDateString().split(' ');
    }
    const yyyy = mydate.getFullYear().toString();
    const dd = (mydate.getDate()).toString();
    if (this.dateparts[0] === yyyy) {
      first = 'yyyy';
    } else if (this.dateparts[0] === dd) {
      first = 'dd';
    } else {
      if (this.dateparts[0].length <= 2) {
        first = 'MM';
      } else {
        first = 'MMM';
      }
    }
    if (this.dateparts[1] === yyyy) {
      second = 'yyyy';
    } else if (this.dateparts[1] === dd) {
      second = 'dd';
    } else {
      if (this.dateparts[1].length <= 2) {
        second = 'MM';
      } else {
        second = 'MMM';
      }
    }
    if (this.dateparts[2] === yyyy) {
      third = 'yyyy';
    } else if (this.dateparts[2] === dd) {
      third = 'dd';
    } else {
      if (this.dateparts[2].length <= 2) {
        third = 'MM';
      } else {
        third = 'MMM';
      }
    }
    this.dateformat = first + separator + second + separator + third;
    return this.dateformat;
  }
  private CheckTimeFormat(): boolean {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  private formatTime(date: Date): string {
    return date.toLocaleDateString();
  }
  private GetTotalBill(): string {
    return this.listConsumptionBasedProduct.reduce((subtotal, item) => subtotal + item.Billing, 0).toFixed(2);
  }
  private OpenDatePicker(id: string) {
    const element = document.querySelector('#' + id + '> div input' ) as HTMLBodyElement;
    element.click();
  }
  private GetBillingReportDetails() {
    if (this.selectedMonth !== undefined && this.selectedMonth !== null && this.selectedMonth > 0) {
      this.isReportLoading = true;
      const selectedMonth = this.selectedMonth;
      const date = new Date();
      const setSelectedMonth = date.setMonth(selectedMonth);
      const selectedDate = new Date(setSelectedMonth);
      const selectedMonthDetail = this.MonthNameDataList.find((a: any) => a.ID === selectedMonth);
      const fullYear: number = selectedMonthDetail.Month_Name.substr(-4);
      const startingMonthDate = new Date(fullYear, selectedDate.getMonth(), 1);
      const endingMonthDate = new Date(fullYear, selectedDate.getMonth() + 1, 0);

      const startDateString = new Date(startingMonthDate).toDateString();
      const endDateString = new Date(endingMonthDate).toDateString();
      const filterObject = {
        applicationid: this.applicationId,
        startDate: startDateString,
        endDate: endDateString,
      };
      FetchData('/ApplicationLicenseV2/GetConsumptionBasedBillingDetails', JSON.stringify({
        filter: JSON.stringify(filterObject),
      }))
        .then((response) => {
          const data = response;
          this.SetConsumptionBasedBillingDate(data);
          this.isReportLoading = false;
        })
        .catch(() => {
          this.isReportLoading = false;
        });
    }
  }
  private SetConsumptionBasedBillingDate(data: any) {
    if (data.length !== 0) {
      this.listConsumptionBasedProduct = data;
    } else {
      this.listConsumptionBasedProduct = [];
    }
  }
  private getLastTwelveMonthList() {

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date();
    date.setMonth(date.getMonth());
    const currentMonth = new Date().getMonth();
    this.selectedMonth = currentMonth;
    for (let i = 1; i <= 12; i++) {

      const monthdata = { ID: this.getMonth(monthNames[date.getMonth()]), Month_Name: monthNames[date.getMonth()] + ' - ' + date.getFullYear() };
      this.MonthNameDataList.push(monthdata);
      date.setMonth(date.getMonth() - 1,1);
    }
  }
  private getMonth(monthStr: any) {
    return new Date(monthStr + '-1-01').getMonth();
  }
}
export default toNative(LicenseDetails);
